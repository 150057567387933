import 'material-design-icons-iconfont/dist/material-design-icons.css'

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import App from './App.vue'
import router from './router'
import VueLogger from 'vuejs-logger'
import "vuetify/dist/vuetify.min.css"
//import VListItem from 'vuetify/lib'
import store from './store/store'


const options = {
  isEnabled: true,
  logLevel : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : false,
  separator: '|',
  showConsoleColors: true
};

Vue.use(VueLogger, options)
Vue.use(Vuetify)
Vue.use(router)
Vue.use(store)
Vue.config.productionTip = true
//Vue.component('v-list-item', VListItem)



new Vue({
  router,
  store,
  vuetify: new Vuetify({ theme: { dark: true }, icons: { iconfont: 'md'}}),
  render: h => h(App)
}).$mount('#app')
