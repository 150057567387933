import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store.js'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue'),
        meta: {
            requireAuth: false,
            requireAdmin: false
        },
        props: true
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "Home" */ '@/views/About.vue'),
        meta: {
            requireAuth: false,
            requireAdmin: false
        },
        props: true
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "Login" */ '@/views/Login.vue'),
        meta: {
            requireAuth: false,
            requireAdmin: false
        },
        props: true
    },
    {
        path: '/locus',
        name: 'locusedit',
        component: () => import(/* webpackChunkName: "LocusEdit" */ '@/views/locus/edit.vue'),
        meta: {
            requireAuth: true,
            requireAdmin: true
        },
        props: true
    },
    {
        path: '/search',
        name: 'samplesearch',
        component: () => import(/* webpackChunkName: "SampleSearch" */ '@/views/sample/SampleSearch.vue'),
        meta: {
            requireAuth: true,
            requireAdmin: false
        }
    },
    {
        path: '/upload',
        name: 'upload',
        component: () => import(/* webpackChunkName: "Upload" */ '@/views/upload/Upload.vue'),
        meta: {
            requireAuth: true,
            requireAdmin: true
        },
        props: true
    },
    {
        path: '/user',
        name: 'user',
        component: () => import(/* webpackChunkName: "User" */ '@/views/user/User.vue'),
        meta: {
            requireAuth: true,
            requireAdmin: true
        },
        props: true
    },
    {
        path: '/pw',
        name: 'pw',
        component: () => import(/* webpackChunkName: "Pw" */ '@/views/user/Pw.vue'),
        meta: {
            requireAuth: true,
            requireAdmin: false
        },
        props: true
    },
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    store.dispatch('updateUser').then(() => {
        if (to.matched.some(record => record.meta.requireAuth)) {
            if (!store.getters.isLoggedIn) {
                next({
                    name: 'login',
                    params: {
                        nextUrl: to.fullPath,
                        reason: 'NOTLOGGEDIN'
                    }
                })
            } else {
                if (to.matched.some(record => record.meta.requireAdmin)) {
                    if (store.getters.isAdmin) {
                        next()
                    } else {
                        next({
                            name: 'login', params: {
                                nextUrl: to.fullPath,
                                reason: 'NOTADMIN'
                            }
                        })
                    }
                } else {
                    next()
                }
            }
            // No need for auth
        } else if (to.matched.some(record => !(record.meta.requireAuth))) {
            next()
        } else {
            next()
        }
    })
})

export default router
