import Vue from 'vue'
import Vuex from 'vuex'
import {default as loginApi} from '@/apis/login.js';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        status: '',
        user: null,
        roles: Array(),
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state) {
            state.status = 'loggedin'
        },
        auth_error(state, err) {
            state.status = 'error ' + err
            state.user = null
            state.roles = Array()
        },
        auth_logout(state) {
            state.status = 'loggedout'
            state.user = null
            state.roles = Array()
        },
        auth_user(state, data) {
            state.user = data.username
            state.roles = data.roles
            state.status = 'loggedin'
        }
    },
    actions: {
        updateUser({commit}) {
            return new Promise((resolve, reject) => {
                loginApi.logged()
                    .then(resp => {
                        if (resp.data.username === "Anonymous" || resp.data.username === null) {
                            commit('auth_logout')
                        } else {
                            commit('auth_user', resp.data)
                        }
                        resolve(resp.data)
                    })
                    .catch(err => {
                        commit('auth_error', err)
                        reject(err)
                    })
            })
        },
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                loginApi.login(user.username, user.password)
                    .then(resp => {
                        commit('auth_success')
                        commit('auth_user', resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('auth_error', err)
                        reject(err)
                    })
            })
        },
        logout({commit}) {
            /* eslint-disable no-console */
            console.log("Logging out")
            /* eslint-enable no-console */

            return new Promise((resolve, reject) => {
                commit('auth_request')
                loginApi.logout()
                    .then(resp => {
                        /* eslint-disable no-console */
                        console.log("Commiting the update")
                        /* eslint-enable no-console */

                        commit('auth_logout')
                        this.dispatch('updateUser').then(() => {
                            resolve(resp)
                        })
                    })
                    .catch(err => {

                        commit('auth_error')
                        reject(err)
                    })
            })
        },
    },
    getters: {
        isLoggedIn: state => state.status === "loggedin",
        authStatus: state => state.status,
        username: state => state.user,
        isAdmin: state => state.roles.includes("ROLE_ADMIN")
    }
})