<template>
  <v-list dense>
    <div v-if="username">
      <v-list-group
          prepend-icon="account_circle"
          no-action
      >
        <template v-slot:activator>
          <v-list-item-title>Logged as {{ username }}</v-list-item-title>
        </template>
        <v-list-item link to="pw">
          <v-list-item-action>
            <v-icon>lock</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>Change password</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-action>
            <v-icon>logout</v-icon>
          </v-list-item-action>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>

      </v-list-group>

      <v-divider/>
    </div>
    <v-list-item link to="/">
      <v-list-item-action>
        <v-icon>home</v-icon>
      </v-list-item-action>

      <v-list-item-content>
        <v-list-item-title>Home</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider/>

    <v-list-item link to="/about">
      <v-list-item-action>
        <v-icon>info</v-icon>
      </v-list-item-action>

      <v-list-item-content>
        <v-list-item-title>About</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider/>

    <div v-if="username">
      <v-list-item link to="/search">
        <v-list-item-action>
          <v-icon>search</v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>Search</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider/>


      <div v-if="isAdmin">

        <v-list-group
            prepend-icon="settings"
            no-action
        >
          <template v-slot:activator>
            <v-list-item-title>Admin</v-list-item-title>
          </template>

          <v-list-item link to="/user">
            <v-list-item-action>
              <v-icon>badge</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Users</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link to="/locus">
            <v-list-item-action>
              <v-icon>format_line_spacing</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Locus</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link to="/upload">
            <v-list-item-action>
              <v-icon>open_in_browser</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Upload</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </div>
    </div>
    <div v-else>
      <v-list-item link to="/login">
        <v-list-item-action>
          <v-icon>lock</v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>Login</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>

  </v-list>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  name: "Menu",
  computed: mapGetters({
    username: "username",
    isAdmin: "isAdmin"
  }),
  methods: {
    logout() {
      this.$store.dispatch('logout')
          .then(() => this.$router.push('home'))
          .catch(err => this.error = "Logout error " + err)
    }
  }
}
</script>

<style scoped>

</style>