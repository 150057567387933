import axios from 'axios'

const SERVER_URL = '/api'

const cookie = document.cookie.match(new RegExp(`XSRF-TOKEN=([^;]+)`))
let CSRF_TOKEN
if (cookie != null) {
    CSRF_TOKEN = cookie[1]
} else {
    CSRF_TOKEN = ""
}

const instance = axios.create({
    baseURL: SERVER_URL,
    timeout: 10000,
    headers: { "X-XSRF-TOKEN": CSRF_TOKEN }
});


function logged() {
    return instance.get('/logged',
        {
            transformResponse: [function (data) {
                const loginInfo = JSON.parse(data)
                let username = loginInfo.username
                let roles = loginInfo.roles

                if (username === "Anonymous") {
                    username = null
                    roles = null
                }

                return {username: username, roles: roles}
            }]
        })
}

export default {
    login: (username, password) => {
        const bodyFormData = new FormData()
        bodyFormData.set('username', username)
        bodyFormData.set('password', password)

        return instance.post('/login', bodyFormData,
            {
                headers: {'Content-Type': 'multipart/form-data'},
            }
        ).then(() => {
            return logged()
        })
    },
    logout: () => instance.post('/logout', {responseType: 'text'}).then(() => { return logged()}),
    logged: () => logged()
}
