<template>
    <v-app dark>
        <v-navigation-drawer
                v-model="drawer"
                app
                right
        >
            <Menu/>
        </v-navigation-drawer>

        <v-app-bar
                app
                :color="primaryColor"
                dark
        >
            <v-spacer/>

            <v-toolbar-title><v-img src="/img/logo_small.png" max-height="32" height="32" alt="FauxDIS"></v-img></v-toolbar-title>

            <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
        </v-app-bar>

        <v-content>
            <v-container ma-0 fluid
            >
                <v-row>
                    <v-col class="text-center" ma-0>
                        <router-view></router-view>
                    </v-col>
                </v-row>
            </v-container>
        </v-content>

        <v-footer
                :color="primaryColor"
                app
        >
            <v-spacer/>

            <span class="white--text">&copy; 2020 The FauxDIS team.</span>
        </v-footer>
    </v-app>
</template>

<script>
    import Menu from '@/views/Menu.vue'

    export default {
        name: 'App',

        components: {Menu},
        data: () => ({
            drawer: null,
            primaryColor: "blue-grey darken-2"
        }),
        created() {
            this.$vuetify.theme.dark = true
        },
        mounted() {
            this.$store.dispatch('updateUser')
        }
    }
</script>
